import React from "react";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import {
  FaFacebook,
  FaLinkedinIn,
  FaXTwitter,
  FaPhone,
  FaEnvelope
} from "react-icons/fa6";
import { Card } from "react-bootstrap";

export default function Footer() {
  return (
    <footer className="sticky-footer text-center  mt-auto">
      <div className="Footer-color">
        <Card style={{ width: "18rem" }} className="Footer-color">
          <Card.Body>
            <Card.Title className="Footer-color">
              <Navbar.Brand as={Link} to="/">
                <h5 className="mb-0">Attika Solutions Ltd</h5>
              </Navbar.Brand>
            </Card.Title>
            <Card.Text>
              29 Cooke Rise, Warfield
              <br />
              Bracknell
              <br />
              United Kingdom
              <br />
              <br />
              <a
                href="https://www.facebook.com/attikasolutions"
                alt="+44 1344306961"
              >
                <i>
                  <FaPhone size={"1em"} color={"bfbfbf"} />
                </i>
              </a>{" "}
              +44 1344306961
              <br />
              <a
                href="https://www.facebook.com/attikasolutions"
                alt="info@attikas.com"
              >
                <i>
                  <FaEnvelope size={"1em"} color={"bfbfbf"} />
                </i>
              </a>{" "}
              info@attikas.com
            </Card.Text>
          </Card.Body>

          <Card.Footer content="card-footer d-flex">
            <ul className="horizontalNoBullets">
              <li>
                <a
                  href="https://www.facebook.com/attikasolutions"
                  alt="facebook"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i>
                    <FaFacebook size={"1.5em"} color={"bfbfbf"} />
                  </i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/attikasolutions"
                  alt="linkedin"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i>
                    <FaLinkedinIn size={"1.5em"} color={"bfbfbf"} />
                  </i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.twitter.com/attikasolutions"
                  alt="twitter"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaXTwitter size={"1.5em"} color={"bfbfbf"} />
                </a>
              </li>
            </ul>
          </Card.Footer>
        </Card>
        <div className="text-center p-0" style={{ color: "white" }}>
          <span className="text-center p-2" style={{ color: "#bfbfbf" }}>
            © 2024 Attika Solutions Ltd &nbsp; &nbsp;
            <a
              href="/privacypolicy"
              alt="Privacy Policy"
              style={{ color: "#cccccc" }}
            >
              Privacy Policy
            </a>
            <a
              href="/termsandconditions"
              alt="Terms & Conditions"
              style={{ color: "#cccccc" }}
            >
              &nbsp;|&nbsp;Terms & Conditions
            </a>
            <a href="/slavery" alt="License" style={{ color: "#cccccc" }}>
              &nbsp;|&nbsp;Slavery
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
}
