import React from "react";
import { NavLink, Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";

const Header = () => {
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Navbar.Brand as={Link} to="/">
        <h5 className="mb-0">Attika Solutions</h5>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <nav>
          <NavLink to="/">Home</NavLink>
          {" | "}
          <NavLink to="/about">About</NavLink>
        </nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
