import React from "react";
import PageHeader from "../common/PageHeader";

export default function TermsandConditions() {
  return (
    <>
      <section
        id="content"
        className="container clearfix"
        style={{
          padding: "0px",
          outline: "none",
          margin: "0px auto",
          marginBottom: "0px"
        }}
      >
        <PageHeader title={"Terms and Conditions"}></PageHeader>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px",
            textAlign: "left"
          }}
        >
          <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
            WEBSITE TERMS AND CONDITIONS{" "}
          </strong>
          <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          The following website and condition is for the website
          www.attikas.com.
        </p>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px"
          }}
        >
          <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
            Website access
            <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          </strong>
          It is not necessary to register with us in order to use most parts of
          this Website. [However, particular areas of this Website will only be
          accessible only if you have registered.]
        </p>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px"
          }}
        >
          <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
            Use of website
          </strong>
          <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          This Website may be used for your own private purposes and in
          accordance with these terms of use. You may print and download
          material from this Website provided that you do not modify or
          reproduce any content without our prior written consent.
        </p>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px"
          }}
        >
          <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
            Site uptime
          </strong>
          <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          All reasonable measures are taken by us to ensure that this Website is
          operational al day, every day. However, occasionally technical issues
          may result in some downtime and accordingly we will not be liable if
          this website is unavailable at any time. Where possible we always try
          to give advance warning of maintenance issues that may result in
          Website down time but we shall not be obliged to provide such notice.
        </p>
        <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
          Visitor provided material
        </strong>
        <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
        <ul className="noBullets">
          <li>
            <strong>1</strong>. Any material that a visitor to this Website
            sends or posts to this Website shall be considered non-proprietary
            and non confidential. We shall be entitled to copy, disclose,
            distribute or use for such other purpose as we deem appropriate all
            material provided to us, with the exception of personal information,
            the use of which is covered under our{" "}
            <a
              href="http://www.attikas.com/privacypolicy/"
              title="Privacy Policy"
              style={{
                margin: "0px",
                padding: "0px",
                outline: "none",
                textDecoration: "none",
                transition:
                  "color 0.2s, background-color 0.2s, border 0.2s, opacity 0.2s ease-in-out",
                color: "rgb(72, 123, 206)"
              }}
            >
              Privacy Policy
            </a>
            .
          </li>
          <li>
            <strong>2</strong>. It is asked that the source of any documents
            which are sourced from the website are credited in your work.
          </li>
          <li>
            <strong>3</strong>. When using this website you shall not post or
            send to or from this Website any material.
          </li>
          <li>
            <ul>
              <li>
                for which you have not obtained all necessary consents; that is
                discriminatory, obscene, pornographic, defamatory, liable to
                incite racial hatred, in breach of confidentiality or privacy,
                which may cause annoyance or inconvenience to others, which
                encourages or constitutes conduct that would be deemed a
                criminal offence, give rise to a civil liability, or otherwise
                is contrary to the law in the United Kingdom;
              </li>
              <li>
                which is harmful in nature including, and without limitation,
                computer viruses, Trojan horses, corrupted data, or other
                potentially harmful software or data.
              </li>
              <li>
                For which you have not obtained all necessary licences and/or
                approvals
              </li>
            </ul>
          </li>
          <li>
            <strong>4</strong>. We will fully co-operate with any law
            enforcement authorities or court order requiring us to disclose the
            identity or other details of any person posting material to this
            website in breach of Paragraph <strong>3</strong>.
          </li>
          <li>
            <strong>5</strong>. We make no guarantees that the Site is free from
            so called computer viruses. It is strongly recommended that you
            check for such viruses before downloading it or its contents
          </li>
        </ul>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px"
          }}
        />
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px"
          }}
        >
          <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
            Links to and from other websites
          </strong>
          <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          <ul className="noBullets">
            <li>
              <strong>A</strong>. Throughout this website are links to third
              party websites. The provision of a link does not mean that the
              Attika Solutions Ltd endorse that link or that website. If you
              visit a website via a link on the Attika Solutions Ltd website
              then you do so at your own risk.
            </li>
            <li>
              <strong>B</strong>. Any party wishing to link to this website are
              entitled to do so provided that the below conditions are observed:
              <ul>
                <li>
                  You do not seek to imply that we are endorsing the services or
                  products of another party unless this has been agreed with us
                  in writing
                </li>
                <li>
                  You do not misrepresent your relationship with this website
                </li>
                <li>
                  The website from which you link to this website does not
                  contain offensive or otherwise controversial content or,
                  content that infringes any intellectual property rights or
                  other rights of a third party
                </li>
              </ul>
            </li>
            <li>
              <strong>C</strong>. By linking to this website in breach of clause{" "}
              <strong>B</strong> you shall indemnify us for any loss or damage
              suffered to this Website as a result of such linking
            </li>
          </ul>
        </p>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px"
          }}
        >
          <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
            Disclaimer
            <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          </strong>
          Whilst we do take all reasonable steps to make sure that the
          information on this website is up to date and accurate at all times we
          do not guarantee that all material is accurate and ,or up to date. All
          material contained on this Website is provided without any or warranty
          of any kind. You use the material on this Website at your own
          discretion.
        </p>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px"
          }}
        >
          <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
            Exclusion of liability
          </strong>
          <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          We do not accept liability for any loss or damage that you suffer as a
          result of using this Website. Nothing in these Terms of Use shall
          exclude or limit liability for death or personal injury caused by
          negligence which cannot be excluded or under the law of the United
          Kingdom.
        </p>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px"
          }}
        >
          <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
            Law and jurisdiction
          </strong>
          <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          These terms of use are governed by English law. Any dispute arising in
          connection with these terms of use shall be subject to the exclusive
          jurisdiction of the Courts of England and Wales.
        </p>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px"
          }}
        >
          <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
            Our details
          </strong>
          <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          Our business’s name is: Attika Solutions Ltd
        </p>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px"
          }}
        >
          Our business address is:
        </p>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px"
          }}
        >
          Attika Solutions Ltd
          <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          29 Cooke Rise
          <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          Warfield
          <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          Bracknell, RG42 2QN
          <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          United Kingdom
        </p>{" "}
      </section>
    </>
  );
}
