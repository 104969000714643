import React from "react";
import MainPresenter from "./MainPresenter";
//import { Link } from "react-router-dom";

const HomePage = () => (
  <div className="p-0 mb-4">
    <MainPresenter></MainPresenter>
    <div className="p-0 mt-5 ">
      <div className="p-8">
        <h4>Solutions</h4>
        <h5>For every question, there is an answer.</h5>
        <p>
          We like challenges. Or to be more precise, we like to find answers to
          your business questions. It’s what we do best; we’re problem solvers.
          Of course, every situation is different. So every solution will be
          unique. That’s why we carefully determine your needs through our
          highly focused methodology, before developing a custom-made solution
          to help you accomplish what you want to achieve. ​Our approach is to
          assemble the necessary resources as a team or multiple teams to
          address your needs. Teams could work at your site or at our offices.
          The solution could involve our highly customised agnostic SaaS
          solution Smart Locator&reg;.The solution may involve one, some or all
          of the areas of our expertise.
        </p>
      </div>
      <div className="p-0 mt-5 ">
        <h4>Products</h4>
        <h5>Attika Solutions ERP</h5>
        <p>
          Attika® Solutions ERP is powered by Smart Locator®. A business
          solution for a wide spectrum of enterprises. It can operate in stand
          alone services to better serve the specific business capability and
          needs. While its expandable and flexible modularity allows to address
          multi enterprise functions as business grows. It is a scalable
          solution to sustain high workloads with predictable performance. It is
          operating system and cloud platform agnostic; while we can interface
          with all major operating systems and cloud providers. Gaining in
          business productivity by quick deployment with zero loss of
          productivity
        </p>
      </div>
    </div>
  </div>
);

export default HomePage;
