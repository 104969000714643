import React from "react";
import PageHeader from "../common/PageHeader";

export default function Slavery() {
  return (
    <section
      id="content"
      className="container clearfix"
      style={{
        padding: "0px",
        outline: "none",
        margin: "0px auto",
        marginBottom: "0px"
      }}
    >
      <PageHeader title={"Slavery and Human Trafficking "}></PageHeader>
      <p
        style={{
          margin: "0px",
          padding: "0px",
          outline: "none",
          marginBottom: "20px",
          textAlign: "left"
        }}
      >
        <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
          Pursuant to UK Modern Slavery Act 2019
        </strong>
      </p>
      <p
        style={{
          margin: "0px",
          padding: "0px",
          outline: "none",
          marginBottom: "20px"
        }}
      >
        <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
          MODERN SLAVERY AND HUMAN TRAFFICKING STATEMENT 
        </strong>
      </p>
      <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
        Introduction 
      </strong>
       
      <p
        style={{
          margin: "0px",
          padding: "0px",
          outline: "none",
          marginBottom: "20px"
        }}
      >
        Modern slavery is a heinous crime and a morally reprehensible act that
        deprives a person&#39s liberty and dignity for another person&#39;s
        gain. At Attika Solutions Ltd, we have a zero-tolerance approach to
        modern slavery and are fully committed to preventing slavery and human
        trafficking in our operation and supply chain. We have taken concrete
        steps to tackle modern slavery, as outlined in our statement. This
        statement sets out the actions that we have taken to understand all
        potential modern slavery risks related to our business, and to implement
        steps to prevent slavery and human trafficking during the financial year
        2024.
      </p>
      <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
        Our business 
      </strong>
      <p
        style={{
          margin: "0px",
          padding: "0px",
          outline: "none",
          marginBottom: "20px"
        }}
      >
        Provides:
        <ul>
          <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
            Software development services 
          </li>
          <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
            Information Technology Specialists 
          </li>
          <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
            Data Collection Equipment 
          </li>
        </ul>
      </p>
      <p
        style={{
          margin: "0px",
          padding: "0px",
          outline: "none",
          marginBottom: "20px"
        }}
      >
        Operates:
        <ul>
          <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
            In United Kingdom
          </li>
        </ul>
      </p>
      <p
        style={{
          margin: "0px",
          padding: "0px",
          outline: "none",
          marginBottom: "20px"
        }}
      >
        <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
          Our supply chains
        </strong>
        <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
        We establish a relationship of trust and integrity with all our
        suppliers, which is built upon mutually beneficial factors. Our supplier
        selection and on-boarding procedure includes due diligence of the
        supplier&apos;s reputation, respect for the law, compliance with health,
        safety and environmental standards, and references.  We haven&apos;t
        been made aware of any allegations of human trafficking/slavery
        activities against any of our suppliers, but if we were, then we would
        act immediately against the supplier and report it to the authorities.{" "}
      </p>
      <p
        style={{
          margin: "0px",
          padding: "0px",
          outline: "none",
          marginBottom: "20px"
        }}
      >
        We act as the “main vendor”, and we will work with other vendors that
        provide workers to hiring companies. Some specialists might work via
        their limited companies. Others might be introduced to us from other
        businesses that accommodate such hiring. Some of the specialists might
        apply directly to our published adverts. All of the hired specialists
        are identified by our employees and apply are policies and legal
        requirements to ensure sound hiring processes.
      </p>
      <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
        Policies
      </strong>
      <p
        style={{
          margin: "0px",
          padding: "0px",
          outline: "none",
          marginBottom: "20px"
        }}
      >
        Attika Solutions Ltd operates the following policies for identifying and
        preventing slavery and human trafficking in our operations:  
        <ul>
          <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
            Anti-Bribery and Corruption Policy
          </li>
          <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
            Code of Conduct, Bullying and Harassment Policy
          </li>
          <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
            Equal Opportunities and Diversity Policy
          </li>
          <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
            Purchasing Code - Policy we have updated our Purchasing Code and
            supplier contracts to make explicit reference to slavery and human
            trafficking.
          </li>
          <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
            Whistleblowing Policy - we encourage all employees, customers and
            suppliers to report any suspicion of slavery or human trafficking
            without fear of retaliation. We provide a confidential helpline to
            protect the identity of the whistle blowers.
          </li>
        </ul>
      </p>
      <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
        Policy Development and Review
      </strong>
       
      <p
        style={{
          margin: "0px",
          padding: "0px",
          outline: "none",
          marginBottom: "20px"
        }}
      >
        Attika Solutions’ policies are established by our senior leadership team
        based on advice from Human Resource professionals, industry best
        practice and legal advice. We review our policies annually, or as needed
        to adapt to changes.
      </p>
      <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
        Awareness
      </strong>
      <p
        style={{
          margin: "0px",
          padding: "0px",
          outline: "none",
          marginBottom: "20px"
        }}
      >
        Attika Solutions Ltd has raised awareness of modern slavery issues by
        putting up posters across our facilities and sending an email that is
        focused specifically on modern slavery to all our staff, which
        explains:  
        <ul>
          <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
            Our commitment in the fight against modern slavery  
          </li>
          <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
            Red flags for potential cases of slavery or human trafficking  
          </li>
          <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
            How employees should report suspicions of modern slavery 
            <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          </li>
        </ul>
      </p>
      <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
        Managing Risk 
      </strong>
      <p
        style={{
          margin: "0px",
          padding: "0px",
          outline: "none",
          marginBottom: "20px"
        }}
      >
        We ensure a high level of understanding of the risks of modern slavery
        and human trafficking in our supply chains and our business, we provide
        training to relevant members of staff. All Directors have been briefed
        on the subject. Our staff is encouraged to discuss any concerns that
        they have.
      </p>
      <p
        style={{
          margin: "0px",
          padding: "0px",
          outline: "none",
          marginBottom: "20px"
        }}
      >
        We haven&#39;t been made aware of any allegations of human
        trafficking/slavery activities against any of our suppliers, but if we
        were, then we would act immediately against the supplier and report it
        to the authorities.
      </p>
      <p
        style={{
          margin: "0px",
          padding: "0px",
          outline: "none",
          marginBottom: "20px"
        }}
      >
        This statement covers 31 April 2024 and has been approved by the board
        of Attika Solutions Ltd at the board meeting on 01 May 2023. 
      </p>
      <p
        style={{
          margin: "0px",
          padding: "0px",
          outline: "none",
          marginBottom: "20px"
        }}
      >
        Evan Parissis 
      </p>
      <p
        style={{
          margin: "0px",
          marginTop: "-20px",
          outline: "none"
        }}
      >
        Director
      </p>
       
      <p
        style={{
          margin: "0px",
          padding: "0px",
          outline: "none",
          marginBottom: "20px"
        }}
      >
        01 January 2024 
      </p>{" "}
    </section>
  );
}
