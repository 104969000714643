import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
//import img1 from "./assets/banner1-940x350.jpg";
//import img2 from "./assets/banner2-940x350.jpg";
//import img3 from "./assets/banner3-940x350.jpg";

const data = [
  {
    id: 1,
    src: require("./assets/banner1-940x350.jpg"),
    caption: "Software. Development. Innovation.",
    description:
      "Custom-made software development - bespoke, intelligent and with the power to transform your business.",
    alt: "Innovation"
  },
  {
    id: 2,
    src: require("./assets/banner2-940x350.jpg"),
    caption: "Tomorrow's technology. Today.",
    description:
      "Groundbreaking software development using the very latest web, cloud and business intelligence technology.",
    alt: "Tomorrow's Technology"
  },
  {
    id: 3,
    src: require("./assets/banner3-940x350.jpg"),
    caption: "Software Solutions? No problem.",
    description:
      "We're problem solvers. Our software solutions provide the answers to your key business questions.",
    alt: "Software Solutions"
  }
];
function MainPresenter() {
  const [index, setIndex] = useState(0);

  const handleSelect = selectedIndex => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} className="carousel">
      {data.map(slide => {
        return (
          <Carousel.Item key={slide.id}>
            <img
              key={slide.id}
              className="d-block w-100"
              src={slide.src.default}
              alt={slide.alt}
              loading="lazy"
            />
            <Carousel.Caption>
              <h3>{slide.caption}</h3>
              <p>{slide.description}</p>
            </Carousel.Caption>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

export default MainPresenter;
