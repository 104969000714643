import React from "react";
import PageHeader from "./common/PageHeader";

const PageNotFound = () => (
  <section
    id="content"
    className="container clearfix"
    style={{
      padding: "0px",
      outline: "none",
      margin: "0px auto",
      marginBottom: "0px"
    }}
  >
    <PageHeader title={"Page Not Found"}></PageHeader>
    <h4>The page you requested cannot be found. </h4>
    <p>
      Please check the spelling of the URL or the page you are looking for is
      not available anymore.
    </p>
  </section>
);

export default PageNotFound;
